import { render, staticRenderFns } from "./SearchAppointmentData.vue?vue&type=template&id=628ae64d&scoped=true"
import script from "./SearchAppointmentData.vue?vue&type=script&lang=js"
export * from "./SearchAppointmentData.vue?vue&type=script&lang=js"
import style0 from "./SearchAppointmentData.vue?vue&type=style&index=0&id=628ae64d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "628ae64d",
  null
  
)

export default component.exports